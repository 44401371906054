<app-website-layout [bodyclass]="'bg-white text-black'" title="BLOG" subTitle="Stay Informed with the Latest Trends and Tips in Fixed Income"
    image="assets/website/blog.png">
    <div class="flex w-[200vw]">

    <div class="grid gap-10 px-[5%] md:px-[5%] pt-20 max-w-screen-2xl m-auto w-[100vw]">
        <!-- <div class="">
            <div class="grid gap-6">
                <div *ngFor="let iten of [
                    {icon:'assets/website/cat/fixed income.svg',title:'Fixed Income'},{icon:'assets/website/cat/investments.svg',title:'Investments'},
                    {icon:'assets/website/cat/fixed income.svg',title:'Cybersecurity'},
                    {icon:'assets/website/cat/etfs.svg',title:'ETF’s'}], let i = index">

                    <div (click)="null"
                        class=" cursor-pointer p-5 mr-3 flex flex-row justify-center md:justify-start items-center md:text-left text-gray-900 transition bg-[#FFFFFF05] rounded-md   border-solid border hover:border-[#978EF5] {{1 != i? 'border-transparent':'border-[#978EF5] font-bold'}}">
                        <img height="90" mt-4 [src]="iten.icon" alt="strategie.title" />
                        <p class="text-2xl ml-4 text-gray-900 transition ">{{iten.title}}</p>

                    </div>

                </div>
            </div>
        </div> -->
        <div class="md:col-span-2 grid md:grid-cols-3">
            <div (click)="goToDetails(slide)"  *ngFor="let slide of blog$ |async " class="slide cursor-pointer">

                <div class="flex flex-col  mx-5">
                    <div class="rounded-2xl w-full flex justify-center ">
                        <img class="w-[100%] md:h-[290px] rounded-2xl max-h-52" [src]="CMSAPI +slide.image" />
                    </div>

                    <div class="py-5">
                        <div class="font-normal {{true?'':'text-[#B3B3B3]'}} text-[14px]">{{slide.date}}</div>
                        <div class="font-[500] text-[20px]">
                           {{slide.title}}
                        </div>
                        <div class="font-normal {{true?'':'text-[#B3B3B3]'}} text-[14px] line-clamp-2" [innerHTML]="slide.description">
                        </div>

                    </div>
                </div>


            </div>
        </div>

    </div>
    <div  class="bg-white w-[100vw] px-4 md:px-[5%] {{currentDetails?'-translate-x-[100vw]':''}} duration-150 ease-in-out ">
        <div class="md:flex hidden gap-10 md:-px-10 my-10">
            <app-rounded-icon-buttom
              (btnClick)="currentDetails = null"
              
              [buttonData]="{
                iconUrl:'assets/img/icon/left-arrow.svg' ,
                iconClass:'',
                btnClass: 'border border-[#4A4C57]  hover:bg-yv-primary-100 !bg-transparent'
              }"
            />

          </div>

          <div class="flex flex-col  mx-5">
            <div class="font-normal {{true?'':'text-[#B3B3B3]'}} text-[14px]">{{currentDetails?.date}}</div>

            <div class="rounded-2xl w-full justify-center items-center ">
                <div class="font-[500] text-[55px] pb-5">
                    {{currentDetails?.title}}
                 </div>
                 <div class=" text-right flex justify-start">
                    <img class="inline-block rounded-2xl mb-10 float-right w-5/12" [src]="CMSAPI +currentDetails?.image" />
                 </div>
                
            </div>

            <div class="pb-10">
                
                <div class="font-normal {{true?'':'text-[#B3B3B3]'}} text-[14px]" *ngIf="currentDetails" [dynamicHtml]="currentDetails?.description">
                </div>

                <div class="text-[#6761A8] font-bold my-4 cursor-pointer" (click)="currentDetails?currentDetails = null: goToDetails(currentDetails)">{{!currentDetails?'':'Go Back'}}</div>
            </div>
        </div>
        
    </div>
    
</div>

</app-website-layout>