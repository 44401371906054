import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RoundedIconButtomComponent } from 'src/app/components/rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
  selector: 'app-advisors',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './advisors.component.html',
  styleUrl: './advisors.component.scss'
})
export class AdvisorsComponent {

}
