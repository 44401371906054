import { Injectable } from '@angular/core';
import { CMSServiceRepoRest } from '../adapters/cms/RestAdapter';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class CMSService  extends CMSServiceRepoRest{
  constructor(private httpClient: HttpClient) {
    super(httpClient)
  } 
}
