<div class="{{ inputData.inputFormClass }}">
  <label
    class="block text-yv-white-50 font-poppins mb-1  {{ inputData.labelClass }}"
    for=""
    >{{ inputData.label }}</label
  >
  <div class="flex gap-5 items-center">
    <input
    [defaultValue]="defaultValue"
      class="block !w-full text-yv-white-50 bg-yv-primary-70 border border-transparent focus:border-blue-500 rounded-lg font-poppins leading-6 text-base {{
        editableCLass
      }} {{ inputData.inputClass }} {{isInputDisabled && 'pointer-events-none'}}  focus:border-[#978EF5] focus:outline-none duration-500
      focus:shadow-[0px_0px_30px_0px_#978EF54D]"
      [readonly]="isInputDisabled"
      [type]="inputData.inputType"
      name='{{inputData.name}}'
      [id]="inputData.id"
      [placeholder]="inputData.placeholder"
      [(ngModel)]="inputData.value"
      (input)="onInputChange($event)"
      [formControl]="control"
      style="
        padding: 0.75rem 0.5rem 0.75rem 1rem;
        width: 25rem;
        height: 3.25rem;
      "
    />
    <app-rounded-icon-buttom
      (btnClick)="onEditClick()"
      *ngIf="inputData.isEditable && !showCheck"
      [buttonData]="{
        iconUrl: 'assets/img/icon/edit.svg',
        iconClass: ''
      }"
      class="me-5"
    ></app-rounded-icon-buttom>
    <app-rounded-icon-buttom
      (btnClick)="onCheckClick()"
      *ngIf="showCheck"
      [buttonData]="{
        iconUrl: 'assets/img/icon/check.svg',
        iconClass: ''
      }"
      class="me-5"
    ></app-rounded-icon-buttom>
  </div>
</div>
