import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';
import { CMSService } from 'src/app/services/cms.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [CommonModule,VideoCarouselComponent],
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blog$?: Observable<any>;
  constructor(public CMSService: CMSService){
  }
  ngOnInit() {
    this.blog$ = this.CMSService.get('get/blogs').pipe(map(( val ) => val.sort((a:any,b:any)=> new Date(b.date).getTime() - new Date(a.date).getTime())));

  }

}
