<div class="input-container {{ chatData.classContainer }}">
  <div class="input-icon-left">
    <!-- Ícone à esquerda -->
    <img
      class="h-[1.25rem] w-[1.25rem]"
      src="assets/img/icon/search.svg"
      alt=""
    />
  </div>

  <input
    type="text"
    (keydown)="handelKeyDown($event)"
    [placeholder]="placeholder"
    [style.width]="width"
    class="bg-white rounded-full text-black h-[4.254rem] ps-10 pe-16 focus:drop-shadow-[0_0_15px_rgba(255,255,255,0.5)] duration-150 {{
      chatData.classInput
    }}"
    (input)="onInputChange($event)"
  />

  <div *ngIf="iconRight" class="input-icon-right">
    <!-- Ícone à direita -->
    <app-rounded-icon-buttom
      [buttonData]="{
        iconUrl: iconUrl,
        btnClass: btnClass
      }"
      (btnClick)="onBtnClick()"
    ></app-rounded-icon-buttom>
  </div>
</div>
