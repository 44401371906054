import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { UserServiceRepoRest } from '../adapters/user/RestAdapter';
import { KeycloakService } from 'keycloak-angular';
import { AdminServiceRepoRest } from '../adapters/user/AdminRestAdapter';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService extends UserServiceRepoRest {
  constructor(private httpClient: HttpClient, private keycloakService: KeycloakService) {
    super(httpClient)
  }
  avatarName(): Promise<string> {
    return this.keycloakService.loadUserProfile().then((data: any) => {
      const initials = data.firstName.split(' ')
        .slice(0, 2)
        .map((word: any) => word.charAt(0))
        .join('');
      return initials.toUpperCase();
    });
  }

  getAllowedModules(){
    return(['education','assistant','robo'])
  }

}

@Injectable({
  providedIn: 'root'
})
export class AdminService extends AdminServiceRepoRest implements OnDestroy{
  allowedModules:string[]= []
  products:any[] = []
  allowedWidgets:string[]= []
  constructor(private httpClient: HttpClient) {
    super(httpClient)
    this.getAllowedModules();
  }
  async getAllowedModules(): Promise<any | any[]>{
    let value = false;
    this.get(['organizations',environment.keycloakRealm,'products']).subscribe({
      next: (data: any) =>{
        this.products = data.items.filter((p:any) =>p.enabled);
        this.allowedModules = this.products.map((i:any) => i.code)
        this.getAllowedWidgets(this.products).then((wV) => {
          value = wV
        })

      },
      error:(e:any) => {
        this.allowedModules = ['education','assistant','robo']
        this.getAllowedWidgets( ['education','assistant','robo'])
        return value
      }
    })
  }
  async getAllowedWidgets(products:any[]){
    let value = false
    this.allowedWidgets = []
    products.forEach(element => {
      this.get(['organizations',environment.keycloakRealm,'products',element.id,'widgets'],{}).subscribe({
        next: (data: any) =>{
          this.allowedWidgets.push(...data.items.map((i:any) => i.id))
          value = true;
        },
        error:(e:any) => {
          this.allowedWidgets.push(...['widget','widget1','widget2'])
        }
      })
    });
    return value
  }
  
  ngOnDestroy(): void {
      
  }
}
