import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DetailsData } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit{
  ngOnInit(): void {
      console.log(this.details,"data")
  }
  @Input() listClass?: string;
  @Input() aiChat?: boolean =false;
  @Input() widget?: boolean =false;
  @Input() valueClass?: string;
  @Input() enableBar: boolean = false;
  @Input() details?: DetailsData[] = [
    { key: 'Instrument type', value: ' Sovereign Bond' },
    { key: 'Maturity', value: '2025' },
    { key: 'Interest rate', value: '8.75%' },
    { key: 'Yield', value: '5.99%' },
  ];
  calculateProgressLine(itemModule: any): string {
    /* if (typeof itemModule === 'number') {
      if (itemModule <= 20) {
        return "20%";
      } else if (itemModule <= 50) {
        return "50%";
      } else if (itemModule <= 75) {
        return "75%";
      } else if (itemModule >= 100) {
        return "100%";
      }
    } else if (typeof itemModule === 'string') {
      // Remove non-numeric characters
      const numericValue = parseFloat(itemModule.replace(/[^0-9]/g, ''));
      
      if (!isNaN(numericValue)) {
        if (numericValue <= 20) {
          return "20%";
        } else if (numericValue <= 50) {
          return "50%";
        } else if (numericValue <= 75) {
          return "75%";
        } else if (numericValue >= 100) {
          return "100%";
        }
      }
    } */
  
    // Default case or invalid input
    return itemModule.toString() + "%";
  }
  getBackgroundColorClass(i:number) {
    const remainder = i % 4;

    switch (remainder) {
      case 0:
        return 'bg-[#98F2B9]';
      case 1:
        return 'bg-[#6761A8]';
      case 2:
        return 'bg-[#FF9365]';
      case 3:
        return 'bg-[#FF6F65]';
      default:
        return 'bg-[#2CC784]';
    }
  }
}
