<app-website-layout >
    <div
      class=" bg-[#070B20] text-center text-white min-h-[400px]  bg-no-repeat bg-cover overflow-hidden minus-header md:h-[100vh] md:pt-[7%] "
    >
       <div class="z-50 absolute text-center w-full p-5">
        <h2
          class="text-[#978EF5] md:text-[42px] text-[8vw] font-[800] mt-[40px] md:max-w-[55rem] mx-auto"
        >
        Unlocking Fixed Income<br />
        <span class="text-white"> Potential for Wealth Managers & Clients </span>
        </h2>
     
  
        <h2
          class="font-[300] md:text-[1rem] text-[4vw] bottom-4 md:max-w-[60rem] mx-auto text-center "
          style="line-height: 2rem"
        >
        Designed for wealth managers and their clients, our platform streamlines discovery, education, and portfolio management while ensuring seamless trade execution through our trusted broker-dealer network
        </h2>
      </div>
        <div class="absolute bottom-[0] flex gap-10 z-20 px-[10%] md:left-1/2 md:-translate-x-1/2 ">

        <div  appIntersection  class="absolute  md:relative flex gap-5 -translate-y-full md:-translate-y-0 -translate-x-16 md:-translate-x-0">
          <ng-lottie *ngIf="!loading" [width]="width"
            [options]="BoxAnimationLeftOptions" (animationCreated)="animationCreated($event)" />
            </div>
            <div class="absolute  md:relative flex gap-5 -translate-y-full md:-translate-y-0">
            <ng-lottie *ngIf="!loading" [width]="width"
            [options]="BoxAnimationMidOptions" (animationCreated)="animationCreated($event)" />
          </div>
          <div class="absolute  md:relative  flex gap-5 -translate-y-full md:-translate-y-0 translate-x-20 md:-translate-x-0">
            <ng-lottie *ngIf="!loading" [width]="width"
            [options]="BoxAnimationRightOptions" (animationCreated)="animationCreated($event)" />
        </div>
        
      </div>
        <div class="mt-40 w-full absolute bottom-0">
          <img src="assets/website/globe.svg" class="md:w-full md:max-w-[50%] w-[110%] mx-auto" />
        </div>
        
       
    
    </div>
    <div class=" " appIntersection>
      <app-features />
      <div class="h-[30vh] min-h-[300px] md:h-[50vh]  bg-[#6761A8]">

      </div>
        <div class="min-h-[600px] h-[150vh] md:h-[100vh] bg-yv">

        </div>

    </div>
    

    <div class="">
        <div class=" px-[10%] min-h-[500px] lg:h-[70vh]">
          <app-skills />
        </div>
        <div class="h-[120vh] md:h-auto text-center py-20">
          <div class="bg-yv rounded-full p-2 px-6 inline-block mt-10">
            <span class=" text-[#81FFAF]">The future</span>
            of Fixed Income</div>
        
        <div class="text-4xl font-bold p-2 mb-10">
          Essential Tools for Informed Investment Decisions
        </div>
        <!-- CHAT GOES HERE-->
        <app-tabs
        [current]="toolsCurrent"
        (onChange)="setToolsCurrent($event)"
      [tabsData]="{
        active: '!bg-[#2CC784] shadow-lg shadow-[0px_0px_20px_5px_#81FFAF80]',
        class:
          'gap-0 bg-[#FFFFFF11] min-w-[115%] md:min-w-0 !p-2 mb-9 rounded-full',
        classTab: 'border-0'
      }"
      [tabs]="[
        { name: 'Scenario Analysis',  },
        { name: 'Valuation Tools', },
        { name: 'Portfolio Analysis'},
      ]"
    />
        <div class="flex justify-center my-10 min-h-96" appIntersection  (elementVisible)="$event?toolsCurrent = 0:toolsCurrent = -1">

            <ng-lottie  *ngIf="toolsCurrent == 0 && !loading" [width]="(windowWidth <700?windowWidth *0.9:windowWidth/2) + 'px'"
            [options]="ToolsAnimationSAOptions" (animationCreated)="animationCreated($event)" />
            <ng-lottie  *ngIf="toolsCurrent == 1 && !loading" [width]="(windowWidth <700?windowWidth *0.9:windowWidth/2) + 'px'"
            [options]="ToolsAnimationPAOptions" (animationCreated)="animationCreated($event)" />
            <ng-lottie *ngIf="toolsCurrent == 2 && !loading" [width]="(windowWidth <700?windowWidth *0.9:windowWidth/2) + 'px'"
            [options]="ToolsAnimationVTOptions" (animationCreated)="animationCreated($event)" />
        </div>
        </div>

    </div>

    <div class="px-[10%] bg-white ">    <app-blog /></div>




    <div class="min-h-[60vh] px-[10%] md:text-right ">
      <div class="bg-yv rounded-full p-2 px-6 inline-block mt-10 text-center md:mr-[25%]">
        <span class=" text-[#81FFAF]">The Future</span>
        of Fixed Income</div>
    
    <div class="text-4xl font-bold p-2 md:mr-[25%]">
      How It Works
    </div>
    <div class="grid md:grid-cols-2" appIntersection>
       <!-- HOW IT WORKS IMGES/ANIMATIONS GOES HERE-->
      
    <div class="w-full flex justify-center md:p-10 md:-translate-y-16">
      <ng-lottie  *ngIf="!loading" [width]="(windowWidth <700?windowWidth *0.8:windowWidth/3) + 'px'"
      [options]="HowItWorksOptions" (animationCreated)="animationCreated($event)" />
    </div>
  <!-- HOW IT WORKS STEPS GOES HERE-->
    <div class="">
      <ngx-slick-carousel  [config]="slideConfig" #slickModal="slick-carousel">
      <div ngxSlickItem class="text-left slide" (click)="slickModal.slickGoTo(0)">
        <h2 class="uppercase text-[#978EF5] text-xl">Step 1</h2>
        <h1 class="text-3xl">Partner with Us</h1>
        <p>Integrate our tools seamlessly into your systems using our flexible widgets and APIs. Choose specific widgets or a combination that best fits your needs.</p>
      </div>

      <div ngxSlickItem class="text-left slide" (click)="slickModal.slickGoTo(1)">
        <h2 class="uppercase text-[#978EF5] text-xl">Step 2</h2>
        <h1 class="text-3xl">Empower Your Strategy</h1>
        <p>Boost your strategy with our bond assistant, portfolio analytics, and Fixed Income education tools. Select and use the tools that align with your business goals.</p>
      </div>
      <div ngxSlickItem class="text-left slide" (click)="slickModal.slickGoTo(2)">
        <h2 class="uppercase text-[#978EF5] text-xl">Step 3</h2>
        <h1 class="text-3xl">Drive Client Success</h1>
        <p>Leverage data-driven insights and reporting to confidently guide your clients. Opt for the analytics and reporting tools that enhance your advisory capabilities.</p>
      </div>

      <div ngxSlickItem class="text-left slide " (click)="slickModal.slickGoTo(3)">
        <h2 class="uppercase text-[#978EF5] text-xl">Step 4</h2>
        <h1 class="text-3xl">Streamline Operations</h1>
        <p>Execute trades efficiently through our network of trusted brokers and track progress with detailed reporting. Our widgets simplify operations, providing the flexibility to focus on what matters most—your clients.</p>
      </div>
      
        
    </ngx-slick-carousel>
    </div>
  
    
  </div>
    </div>
     <!-- <div class="min-h-[60vh] text-center bg-[#F9F9F9] text-black">
      <div class="bg-yv-primary-100 rounded-full p-2 px-6 inline-block mt-10">
        We are Experts</div>
    
    <div class="text-4xl font-bold p-2">
      Meet Our Advisors
    </div>
    <div class=" px-[10%]">
      <app-advisors />
    </div>
    </div>  -->

    <!--


    <div class="screen text-center  md:text-left pt-20">
      <div class="bg-yv rounded-full p-2 px-6 inline-block mt-10">
        <span class=" text-[#81FFAF]">The Future</span>
        of Fixed Income</div>
    
    <div class="text-4xl font-bold p-2">
      Our Partners
    </div>
  -->
    <!--HERE GOES PARTNERS
    <div class="grid grid-cols-2 md:grid-cols-5 md:mb-20">
      <img *ngFor="let img of ['1.svg','2.svg','3.svg','4.svg','5.svg','6.svg','7.svg','8.svg','9.svg','10.svg']" [src]="'assets/img/partners/1.svg'" 
      class="text-center mx-auto p-5" />
    </div>
    </div>

    <div class="bg-[#6761A8] p-[10%]">
      <app-investments />
    </div>
  -->

     <!-- <div class="px-[10%] bg-white ">    <app-learn /></div> -->
  <app-website-footer />
  </app-website-layout>