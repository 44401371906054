<app-website-layout [bodyclass]="'bg-white text-black'" title="Contact Us" >
    <div class="grid  gap-10 p-[10%] max-w-screen-2xl m-auto">
    
        <div>
            <h2 class="text-yv font-extrabold my-4">General:</h2>
            <h1 class="text-black text-xl underline"><a type="email" href="mailto:info@yieldvestor.com" >info{{'@'}}yieldvestor.com</a></h1>
            <p class="text-black opacity-60">For general queries, including partnership opportunities</p>
        </div>

        
        <div>
            <h2 class="text-yv font-extrabold my-4">Sales:</h2>
            <h1 class="text-black text-xl underline"><a type="email" href="mailto:sales@yieldvestor.com" >sales{{'@'}}yieldvestor.com</a></h1>
            <p class="text-black opacity-60">Interested in speaking with sales for any of our solutions?</p>
        </div>

        
        <div>
            <h2 class="text-yv font-extrabold my-4">Careers:</h2>
            <h1 class="text-black text-xl underline"><a type="email" href="mailto:careers@yieldvestor.com" >careers{{'@'}}yieldvestor.com</a> </h1>
            <p class="text-black opacity-60">Interested in joining our team?</p>
        </div>
    </div>

</app-website-layout>