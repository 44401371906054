import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Output() onChange = new EventEmitter<number>();
  @Input() current:number = 0
  @Input() tabsData:any = {
    classTab:''
  }
  @Input()
  tabs: any[] = [{name:'Bonds',action:()=> {},iconUrl:'assets/img/icon/clock.svg'},{name:'Invests',action:()=> {}},{name:'Etc',action:()=> {}}]

  
change(number:number){
  this.current = number;
  this.onChange.emit(this.current);
}
}
