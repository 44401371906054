<div class=" my-8 gap-4 relative w-full {{home?'text-white':'text-[#4A4C57]'}} text-left">


      <div class="md:flex hidden gap-10 md:-px-10 my-10 justify-end">
        <app-rounded-icon-buttom
          (btnClick)="slickModal.slickPrev()"
          (mouseenter)="changeIconUrl(false,'right-arrow')"
          (mouseleave)="resetIconUrl(false,'left-arrow')"   
          [buttonData]="{
            iconUrl: home ? homeLeftIconUrl : leftIconUrl ,
            btnClass: home ? homeLeftButtonClass : leftButtonClass
          }"
        />
        <app-rounded-icon-buttom
       (btnClick)="slickModal.slickNext()" 
       (mouseenter)="changeIconUrl(true,'right-arrow')"
       (mouseleave)="resetIconUrl(true,'left-arrow')"     
       [buttonData]="{
       iconUrl: home ? homeRightIconUrl : rightIconUrl ,
       btnClass: home ? homeRightButtonClass : rightButtonClass
         }"
/>

      </div>

  <ngx-slick-carousel
    class="carousel py-10 md:m-0"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)"
    (beforeChange)="beforeChange($event)"
  >
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      
      <div
  class="flex flex-col items-center"
>
  <div class="rounded-2xl w-full flex justify-center ">
    <img class="absolute top-1/2 -translate-y-full w-[25%]" src="assets/img/icon/play.svg" />
    <img class="w-[100%] md:max-h-[290px] rounded-[24px]" [src]="slide.img" />
  </div>
  
  <div class="pb-10">
    <div class="font-[500] text-[20px]">
      Do I Need a Fixed Income Education Before Investing?
    </div>

  </div>
</div>


    </div>
  </ngx-slick-carousel>
</div>
