import { NgModule } from '@angular/core';
import { LandpageComponent } from './views/landpage/landpage.component';
import { LandingpageComponent } from './views/landingpage/landingpage.component';
import { RouterModule, Routes } from '@angular/router';
import { FAQComponent } from './views/landingpage/faq/faq.component';
import { BlogPageComponent } from './views/landingpage/blog-page/blog-page.component';
import { BookComponent } from './views/landingpage/book/book.component';
import { AcademyComponent } from './views/landingpage/academy/academy.component';

const routes: Routes = [
  { path: '', component: LandingpageComponent },
  { path: 'faq', component: FAQComponent },
  { path: 'blog', component: BlogPageComponent },
  { path: 'academy', component: AcademyComponent },
  { path: 'book', component: BookComponent },

  {
    path: 'auth',
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'assistant',
    loadChildren: () => import('./views/assistant/assistant.module').then(m => m.AssistantModule)
  },
  {
    path: 'robo',
    loadChildren: () => import('./views/robo/robo.module').then(m => m.RoboModule)
  },
  
  {
    path: 'test',
    loadChildren: () => import('./views/test/test.module').then(m => m.TestModule)
  },
  {
    path: '',
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
  },
  {
    path: '',
    loadChildren: () => import('./codes/codes.module').then(m => m.CodesModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
