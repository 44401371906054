<div class=" text-center  md:text-left bg-white text-black pt-20">
    <div class="bg-yv-primary-100 text-white rounded-full p-2 px-6 inline-block mt-10">
      We are Experts</div>
  
  <div class="text-4xl font-bold p-2">
    Blog
  </div>

  <!-- Blog Carroursel Goes Here-->
  <app-blog-carousel [slides]="blog$ | async" />

  </div>