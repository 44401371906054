<div class="
   rounded-3xl justify-between {{!vertical&&aiChat?'!bg-yv-5%-gray max-w-[19rem] sm:max-w-[25rem] lg:min-h-[46.25rem] ':''}} w-full h-auto bg-yv-primary-70  {{
    assetsData.assetsCardClass
  }} py-[1.2rem] px-[1rem]">
  <div class="{{''}}">
    <div class="px-0 pt-4">
      <div class="flex justify-between'">
        <div class="flex-grow">
          <app-rounded-icon-buttom (btnClick)="onLogoClick()"
            [buttonData]="{ iconUrl: assetsData.logo }"></app-rounded-icon-buttom>
        </div>
        <div class="{{!vertical?'hidden':'inline-flex'}} {{details?'':'hidden'}}  justify-between space-x-3">
          <app-rounded-icon-buttom (btnClick)="onLikeClick()" [buttonData]="{
            iconUrl: assetsStyle.iconLike,
            btnClass: assetsStyle.iconLikeClass
          }"></app-rounded-icon-buttom>
          <app-rounded-icon-buttom (btnClick)="onShareClick()" [buttonData]="{
            iconUrl: assetsStyle.iconShare,
            btnClass: assetsStyle.iconShareClass
          }"></app-rounded-icon-buttom>
          <app-rounded-icon-buttom (btnClick)="onPlayClick()" [buttonData]="{
            iconUrl: assetsStyle.iconPlay,
            btnClass: assetsStyle.iconPlayClass
          }"></app-rounded-icon-buttom>
        </div>
      </div>

      <div id="currencyText"
        class="{{widget?'!min-w-[8rem]':''}} {{!vertical&&aiChat?' text-left':''}} {{!vertical?'':'flex'}} mt-5">
        <div>
          <span class="firstText">{{ assetsData.firstText }} ,</span>
          <span class="currencyType"> {{ assetsData.currencyType }}</span>
        </div>
      </div>

      <div id="codeText" class="flex mb-3">
        <span class="codeText">{{ assetsData.codeText }}</span>
      </div>
    </div>
    <div class="flex-grow ">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="border-t-2 border-solid border-gray-700 mb-[2rem]"></div>

  <!--   <div class="{{!vertical?'hidden': 'flex'}} justify-between items-center mb-5">
    <button (click)="onDetailsClick()" class="font-bold z-10" style="
        font-size: 1rem;
        line-height: 1.25rem;
        color: #978ef5;
        letter-spacing: 0.1rem;
      ">
      Details
    </button>

    <button (click)="onOrderClick()" class="bg-yv-primary rounded-lg text-white" style="
        padding: 1rem, 1.2rem, 1rem, 1.2rem;
        width: 95px;
        height: 52px;
        font-family: Poppins;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
      ">
      Order
    </button>
  </div>
 -->
  <div id="aiChatOptions"
    class="{{!vertical&&aiChat || true? handleSlideSize() : 'hidden'}}">


    <div id="bidButtons" class="grid grid-cols-1 max-w-[10rem] py-[0.5rem] justify-items-center">
      <button (click)="onDetailsClick()" class="bidButton">
        <div class="text-[#978ef5] m-1">Bid</div>
        <div class="text-white m-1">${{bid || price || "N/A"}}</div>
      </button>
      <button (click)="onOrderClick()"
        class="bg-yv-primary-70 text-yv-primary hover:bg-yv-primary hover:text-white sellButton flex items-center justify-center">
        Sell
      </button>
    </div>



    <div id="detailOrderButtons" class="grid grid-cols-1 max-w-[10rem] py-[0.5rem] justify-items-center">
      <button (click)="onDetailsClick()" class="askButton">
        <div class=" text-[#978ef5] m-1">Ask </div>
        <div class="text-white" class="m-1">${{ask || price||"N/A"}}</div>

      </button>

      <button (click)="onOrderClick()" class="bg-yv-primary text-white hover:bg-yv-primary-70 hover:text-yv-primary buyButton flex items-center justify-center">
        Buy
      </button>
    </div>
  </div>
</div>