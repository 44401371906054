import { Component, Input, OnInit, Output, EventEmitter,NgModule, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputForm } from 'src/app/models/ComponentInput';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-form',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent,FormsModule,ReactiveFormsModule],
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
})
export class InputFormComponent implements OnInit {
  // @Input() isEditable: boolean = true;
  @Input() defaultValue:any;
  @Input() control: FormControl = new FormControl()
  @Input() inputData: InputForm = {
    inputFormClass: '',
    label: 'Full Name',
    placeholder: 'My Address',
    inputType: 'text',
    labelClass: '',
    inputClass: '',
    id: '',
    name: 'name',
    isEditable: false,
    value: ''
  };

  @Output() btnCheckClick = new EventEmitter<void>();

  showCheck: boolean = false;

  @Input() isInputDisabled: boolean = false;

  editableCLass: string = '';

  ngOnInit(): void {
    if (this.inputData.isEditable) {
      this.editableCLass = '!bg-transparent';
      this.isInputDisabled = true;
    }
  }

  onEditClick() {
    this.editableCLass = '';
    this.isInputDisabled = false;
    this.showCheck = true;
  }

  onCheckClick() {
    this.editableCLass = '!bg-transparent';
    this.isInputDisabled = true;
    this.showCheck = false;
    this.btnCheckClick.emit();
  }

  @Output() inputValueChange = new EventEmitter<string>();

  onInputChange(event: any) {
    this.inputData.value = event.target.value;
    this.inputValueChange.emit(this.inputData.value);
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
      if(this.inputData?.format != "USD") return;
      else {
        
    let value = input.value.replace(/[^\d.]/g, ''); // Remove non-numeric characters
    value = Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    input.value = value;
  }
  
}
}
