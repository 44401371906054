<app-website-layout title="FAQ" subTitle="Unlock the secret to a smooth investment ride: Dive into Fixed Income!"
    image="assets/website/faq.svg">
    <div class="grid md:grid-cols-3 gap-10 p-[10%] max-w-screen-2xl m-auto">
        <div class="">
            <div class="grid gap-6">
                <div class="py-10  " >
                    <app-input-chat 
                    placeholder="Search"
                    btnClass="bg-transparent" iconUrl="assets/img/icon/assistantSend.svg" [chatData]="{classContainer:'w-full',classInput:'w-full'}"
                    [(inputValue)]="currentKey" 
                    (btnClick)="null" />
                </div>
                <div
                    *ngFor="let iten of [{icon:'assets/img/star.svg',title:'Investments'},{icon:'assets/img/star.svg',title:'Investments'}], let i = index">

                    <div (click)="null"
                        class=" cursor-pointer p-5 mr-3 flex flex-row justify-center md:justify-start items-center md:text-left text-gray-100 transition bg-[#FFFFFF05] rounded-md   border-solid border hover:border-[#978EF5] {{1 != i? 'border-transparent':'border-[#978EF5] font-bold'}}">
                        <img height="90" mt-4 [src]="iten.icon" alt="strategie.title" />
                        <p class="text-2xl ml-4 text-gray-100 transition ">{{iten.title}}</p>

                    </div>

                </div>
            </div>
        </div>
        <div class="md:col-span-2">
            <div class="bg-yv-5%-gray p-10 rounded-2xl">
                <app-rounded-icon-buttom [buttonData]="{iconUrl: 'assets/img/icon/cross.svg',
                btnClass: 'float-right right-0 top-0  justify-end flex',
                iconClass:'brightness-100 invert'}" />
                <h1 class=" text-3xl mb-5">Who is Yieldvestor?</h1>
                <p>Yieldvestor is a digital investing app for Fixed Income products helping fund managers,
                    investment advisors, brokers, digital banks, and smaller fnancial services companies
                    launch digital Fixed Income products quickly</p>
                <p>
                    Our team comprises fnancial technology experts, seasoned Fixed Income traders,
                    and capital market operators with vast and deep expertise across an array of markets.</p>
            </div>
            <div class=" text-2xl flex justify-between p-10">
                <div>Item Two</div> <img src="assets/img/icon/plus.svg" />
            </div>
            <div class=" text-2xl flex justify-between p-10">
                <div>Item Three</div> <img src="assets/img/icon/plus.svg" />
            </div>
        </div>

    </div>

</app-website-layout>