import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CMSService } from 'src/app/services/cms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrl: './blog-page.component.scss'
})
export class BlogPageComponent {
  CMSAPI =environment.CMS_API;
  blog$?: Observable<any>;
  currentDetails?:any;
  constructor(public CMSService: CMSService,private route: ActivatedRoute){
  }
  ngOnInit() {
    this.blog$ = this.CMSService.get('get/blogs').pipe(map(( val ) => val.sort((a:any,b:any)=> new Date(b.date).getTime() - new Date(a.date).getTime())));;
    this.blog$?.subscribe({next: (data) => {
      this.route.queryParams.subscribe(params => {
        const id = params['id'];
        if(id) this.goToDetails(data[id]);
        });
    }})
   
   

  }

  goToDetails(details:any){
    this.currentDetails = details;
  }

}
