import { CommonModule } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-features',
  standalone: true,
  imports: [CommonModule,LottieComponent],
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  constructor(private renderer: Renderer2) { }
  loading = false;
  ngOnInit(): void {
    this.renderer.listen('window', 'resize', () => {
      if(window.innerWidth > 700) {
        this.loading = true;
        if(true)
        setTimeout(() => {
          this.loading = false;
        }, 150);
      }
  });
  }
  
  //Features
  FeaturesAnimationS1Options: AnimationOptions = {path: '/assets/website/animations/features/Section 1.json',};
  FeaturesAnimationS2Options: AnimationOptions = {path: '/assets/website/animations/features/Section 2.json',};
  FeaturesAnimationS3Options: AnimationOptions = {path: '/assets/website/animations/features/Section 3.json',};
  FeaturesAnimationS4Options: AnimationOptions = {path: '/assets/website/animations/features/Section 4.json',};

  get height () {
    return window.innerHeight + 'px';
  }
  get width () {
    var width = window.innerWidth < 700?window.innerWidth*0.9:window.innerWidth*2/4
    return width + 'px';
  }
  get widthAlt () {
    var width = window.innerWidth < 700?window.innerWidth*0.9:window.innerWidth/3
    return width  + 'px';
  }
}
