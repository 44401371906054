import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RoundedIconButtomComponent } from 'src/app/components/rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
  selector: 'app-blog-carousel',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent,SlickCarouselModule],
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent {
  @Input() home: boolean = false;
  slides = [
    { img: 'assets/website/temp/image.png' },
    { img: 'assets/website/temp/image.png' },
    { img: 'assets/website/temp/image.png' },
    { img: 'assets/website/temp/image.png' },
  ];
  slideConfig = {
    centerMode: true,
    arrows: false, slidesToShow: 3, slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  // photoTest: string = 'assets/img/icon/coroucel001.svg';

  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e: any) {
    console.log('slick initialized');
  }

  breakpoint(e: any) {
    console.log('breakpoint');
  }

  afterChange(e: any) {
    console.log('afterChange');
  }

  beforeChange(e: any) {
    console.log('beforeChange');
  }

  rightIconUrl: string = 'assets/img/icon/left-arrow.svg';
  leftIconUrl: string = 'assets/img/icon/left-arrow.svg';
  rightButtonClass: string = 'border border-[#4A4C57] rotate-180';
  leftButtonClass: string = ' border border-[#4A4C57] ';
  homeRightIconUrl: string = 'assets/img/icon/right-arrow.svg';
  homeLeftIconUrl: string = 'assets/img/icon/right-arrow.svg';
  homeRightButtonClass: string = 'border border-[#4A4C57] !bg-transparent';
  homeLeftButtonClass: string = ' border border-[#4A4C57] !bg-transparent rotate-180';

  changeIconUrl(right: boolean, newIconUrl: string) {
    if (right) {
      this.rightIconUrl = `assets/img/icon/${newIconUrl}.svg`;
      this.rightButtonClass = '!bg-yv';
      this.homeRightButtonClass = 'bg-[#F9F9F933] border border-[#FFFFFF]';
    }
    if (!right) {
      this.leftIconUrl = `assets/img/icon/${newIconUrl}.svg`;
      this.leftButtonClass = '!bg-yv rotate-180';
      this.homeLeftButtonClass = 'bg-[#F9F9F933] rotate-180 border border-[#FFFFFF]';
    }
  }

  resetIconUrl(right: boolean, newIconUrl: string) {
    if (right) {
      this.rightIconUrl = `assets/img/icon/${newIconUrl}.svg`;
      this.rightButtonClass = 'border border-[#4A4C57] rotate-180';
      this.homeRightButtonClass = 'border border-[#4A4C57] !bg-transparent';
    }
    if (!right) {
      this.leftIconUrl = `assets/img/icon/${newIconUrl}.svg`;
      this.leftButtonClass = 'border border-[#4A4C57]';
      this.homeLeftButtonClass = 'border border-[#4A4C57] !bg-transparent rotate-180';
    }
  }
}
