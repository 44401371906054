import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-website-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  url;
  constructor(private _router: Router,){


    this.url = _router.url.split("/")[1]
  }
  @Input() title?:string;
  @Input() subTitle?:string ;
  @Input() image?:string ;
  @Input() bodyclass?:string;

}
