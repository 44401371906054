<div class="text-center md:text-left p-4 md:p-10 !pb-4 h-full md:rounded-2xl mx-auto flex-grow md:flex gap-10">

    <div class="md:w-7/12">

    
    <div class="text-4xl md:text-7xl font-bold p-2 md:my-10">
        The Yieldvestor
Difference

    </div>
    <div class="md:text-3xl md:mt-10 text-justify">
        Yieldvestor combines Fixed Income investment opportunities with education and insights. Using our proprietary UX, we deliver a proprietary experience for both you and your customers, making it easy for them to connect with secure investments. With us, you no longer need to develop your own Fixed Income proposition for your customers – we do all that for you. 
    </div>

    <app-button [buttonData]="{
        iconRight: false,
        IconLeft: false,
        disabled: false,
        loading: false,
        classButton: '!bg-[#98F2B9] inline-block mx-auto md:mx-0 my-10 md:mt-20',
        classText: 'text-yv-primary',
        classIcon: '',
        textButton: 'Get started',
        iconRightUrl: '',
      }" /> 
      </div>
      <div class="mt-auto md:mx-auto md:my-auto md:w-1/2 md:p-10">
        <img src="assets/website/mobile-vestor.svg" class="w-full" />
      </div>
</div>


<div class="text-center md:text-left p-4 md:p-10 !pb-4 h-full md:rounded-2xl mx-auto flex-grow md:flex gap-10">
    <div class="mt-auto md:mx-auto md:my-auto md:w-6/12 md:p-10">
        <img src="assets/website/invest-chart.png" class="w-full" />
      </div>

    <div class="md:w-1/2">
    <div class="bg-yv rounded-full p-2 px-6 inline-block mt-10">
        <span class=" text-[#81FFAF]">The Future</span>
        of Fixed Income</div>
    
    <div class="text-4xl md:text-7xl font-bold uppercase p-2 md:my-10">
        INVEST WITH YIELDVESTOR <br />
        APP 

    </div>

    <app-button [buttonData]="{
        iconRight: false,
        IconLeft: false,
        disabled: false,
        loading: false,
        classButton: '!bg-[#98F2B9] inline-block mx-auto md:mx-0 my-10 md:mt-20',
        classText: 'text-yv-primary',
        classIcon: '',
        textButton: 'Start now',
        iconRightUrl: '',
      }" /> 
      </div>
      
</div>