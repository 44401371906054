<div class="text-center md:text-left p-10 absolute w-full flex flex-col items-center justify-center px-[7%] py-20">

<div class="bg-yv rounded-full p-2 px-6 inline-block md:self-start">
    <span class=" text-[#81FFAF]">The future</span>
    of Fixed Income</div>


<div class="text-[56px] font-bold p-2 md:self-start ">
Features
</div>
<img class="absolute top-10 right-0" src="assets/website/feature.svg">
<div>
  
<div class="grid md:flex gap-[32px]">
    <div class=" md:w-2/3">
        <ng-lottie *ngIf="!loading"
        [width]="width"
          [options]="FeaturesAnimationS1Options" />
          </div>
          <div class="md:translate-y-[8%]">
            <ng-lottie *ngIf="!loading"
        [width]="widthAlt"
        [options]="FeaturesAnimationS4Options" />
        </div>
</div>
<div class="grid md:flex gap-[32px]">
        <div class="md:w-2/3">
          <ng-lottie *ngIf="!loading"
          [width]="width"
          [options]="FeaturesAnimationS2Options" />
         
      </div>
      <div class=" ">
         <ng-lottie *ngIf="!loading"
            [width]="widthAlt"
            [options]="FeaturesAnimationS3Options" />
       
    </div>
    <!-- <img src="assets/website/features.svg"  class="h-[80vh]"/> -->

</div>


</div>

</div>