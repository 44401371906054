import { Component, OnInit,Renderer2} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})

export class LandingpageComponent  implements OnInit {
  constructor(private renderer: Renderer2) { }
  loading = false;
  ngOnInit(): void {
    this.renderer.listen('window', 'resize', () => {
      this.resise()
  });
  }
  async resise (){
    if(window.innerWidth > 700) {
      this.loading = true;
      if(true)
        this.loading = false;
      }
    }
  
  slideConfig = {
    dots: false,
    arrows: false,
    vertical:true,
    infinite:false,
    centerMode: true,
    verticalSwiping: true,
    "slidesToShow": 4, "slidesToScroll": 1,
    centerPadding: "100px",
    adaptiveHeight: true,
  };
  
  HowItWorksOptions: AnimationOptions = {path: '/assets/website/animations/How-It-Works-v2.json'};

  BoxAnimationLeftOptions: AnimationOptions = {path: '/assets/website/animations/header/Left.json',};
  BoxAnimationMidOptions: AnimationOptions = {path: '/assets/website/animations/header/Mid.json',};
  BoxAnimationRightOptions: AnimationOptions = {path: '/assets/website/animations/header/Right.json',};


  toolsCurrent = -1;
  setToolsCurrent(e:number){this.toolsCurrent = e;}
  ToolsAnimationPAOptions: AnimationOptions = {path: '/assets/website/animations/tools/Portfolio Analysis.json',loop:false};
  ToolsAnimationVTOptions: AnimationOptions = {path: '/assets/website/animations/tools/Valuation Tools.json',loop:false};
  ToolsAnimationSAOptions: AnimationOptions = {path: '/assets/website/animations/tools/Scenario Analysis.json',loop:false};

  
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  get height () {
    return window.innerHeight + 'px';
  }
  get width () {
    var width = window.innerWidth < 700?window.innerWidth*0.7:window.innerWidth/5
    return width + 'px';
  }

  get windowWidth () {return window.innerWidth}
}
