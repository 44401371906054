<div class=" overflow-hidden bg-[#0C1026] text-white " >
  <app-website-mobile-menu />

    <div class="hidden absolute md:flex items-center w-full justify-between mb-4  p-4 px-[10%]">
      <!-- <app-yieldvestor
        [yieldVestorData]="{
          firstText: 'Yield',
          secondText: 'Vestor',
          logo: 'assets/img/icon/logo.svg',
          firstTextClass: '!text-[#4A4C57]',
          secondTextClass: '!text-[#2CC784]'
        }"
      ></app-yieldvestor> -->
      <img src="assets/website/logo.svg" alt="logo" [routerLink]="'/'" class="cursor-pointer"/>
      <div class="flex items-center menu flex-grow justify-center gap-10">
        <div class="menu-item ml-auto flex-grow"></div>
        <div class="menu-item cursor-pointer ml-auto {{url =='' ? 'text-[#978EF5]' : 'text-[#F9F9F9]'}}" [routerLink]="'/'">
          Home
        </div>
        <!-- <div class="menu-item cursor-pointer ml-auto" [routerLink]="'/academy'">
          Academy
        </div> -->
        <div class="menu-item cursor-pointer ml-auto {{url =='blog' ? 'text-[#978EF5]' : 'text-[#F9F9F9]'}}" [routerLink]="'/blog'">
          Blog
        </div>
        <!-- <div class="menu-item cursor-pointer" [routerLink]="'/faq'">
          FAQ’s
        </div> -->
        <!-- <div class="menu-item cursor-pointer" [routerLink]="'/'">
          About us
        </div> -->
        <div class="menu-item cursor-pointer {{url =='book' ? 'text-[#978EF5]' : 'text-[#F9F9F9]'}}" [routerLink]="'/book'">
          Contact us
        </div>
  
        <div class="menu-item mr-auto flex-grow"></div>
       
        <!-- <div class="menu-item !p-0" >
          <app-button 
          [routerLink]="'/home'"
            [buttonData]="{
              textButton: 'Lets get Started',
              classButton: '!bg-yv !h-[30px] !pt-6 !pb-6',
              classText: '!text-[1rem]'
            }"
          />
        </div> -->
      </div>
    </div>

    <div *ngIf="title || subTitle || image" class="text-center md:text-left p-4 md:p-[10%] !pb-4 bg-gradient-to-tr from-[#0C1026] from-10% via-[#81FFAF11] via-30% to-[#0C1026] to-90% h-full md:rounded-2xl  flex-grow md:flex">

        <div class="md:w-7/12">

        
        <div class="text-4xl md:text-7xl font-bold p-2 uppercase md:my-10">
            {{title}}
        </div>
        <div class="md:text-3xl md:mt-10">
            {{subTitle}}
        </div>
    
       
          </div>
          <div class="mt-auto md:mx-auto md:my-auto md:w-5/12 md:p-10">
            <img [src]="image" class="w-full" />
          </div>
        </div>
        <div [class]="bodyclass">
          <ng-content ></ng-content>
        </div>
    </div>