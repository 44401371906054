import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-website-footer-item',
  templateUrl: './item.component.html',
  styleUrl: './footer.component.scss',
  standalone: true,
})
export class ItemComponent {
  @Input() title = "";
  @Input() subtitle = "";
  @Input() link = "";
  mailTo(link:string){
    window.open('mailto:'+link)

  }
}
@Component({
  selector: 'app-website-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  standalone: true,
  imports: [CommonModule, ItemComponent]
})
export class FooterComponent {
  @Input() alt:boolean = false;
  aler(){
    alert();
  }
}
