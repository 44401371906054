import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { AssetsVisualization } from 'src/app/models/ComponentInput';
import { UserService } from 'src/app/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-assets-visualization',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './assets-visualization.component.html',
  styleUrls: ['./assets-visualization.component.scss'],
})
export class AssetsVisualizationComponent implements OnInit {
  constructor(private userService: UserService) {

  }
  ngOnInit(): void {
    this.assetsData.like instanceof Observable ? this.assetsData.like.subscribe((data) => {
      this.LIKE = data
    }) : this.LIKE = this.assetsData.like || false
  }

  LIKE = false

  @Input() price: any = "";
  @Input() ask: number | string = "";
  @Input() bid: number | string = "";

  @Input() vertical: boolean = true;
  @Input() aiChat: boolean = false;
  @Input() details: boolean = false;
  @Input() flex: boolean = true;
  @Input() assetsData: AssetsVisualization = {
    logo: 'assets/img/icon/logo_gray.svg',
    assetsCardClass: '',
    firstText: 'Alaska, 5.342% 08/2027,',
    codeText: 'US011770U778',
    currencyType: 'USD',
  }
  get assetsStyle() {
    return ({
      iconLike: this.LIKE && this.assetsData.like ? 'assets/img/icon/heart-live.svg' : 'assets/img/icon/heart.svg',
      iconLikeButton: '!bg-yv-5%-gray',
      iconLikeClass: '!bg-yv-5%-gray',
      iconShare: 'assets/img/icon/arrow-two.svg',
      iconShareButton: '!bg-yv-5%-gray',
      iconShareClass: '!bg-yv-5%-gray',
      iconPlay: 'assets/img/icon/playground.svg',
      iconPlayButton: '!bg-yv-5%-gray',
      iconPlayClass: '!bg-yv-5%-gray',
    });
  }
  @Output() btnLogoClick = new EventEmitter<void>();
  @Output() btnLikeClick = new EventEmitter<void>();
  @Output() btnShareClick = new EventEmitter<void>();
  @Output() btnPlayClick = new EventEmitter<void>();
  @Output() btnDetailsClick = new EventEmitter<void>();
  @Output() btnOrderClick = new EventEmitter<void>();

  onLogoClick() {
    this.btnLogoClick.emit();
  }
  onLikeClick() {
    (this.LIKE && this.assetsData.like ?
      this.userService.delete('investor_watch_list_bond/bond/' + this.assetsData.codeText) :
      this.userService.post('investor_watch_list_bond/bond/' + this.assetsData.codeText, {})).subscribe({
        next: (value) => {
          this.LIKE = value;
        },
        error(err) {

        },
      })
    this.btnLikeClick.emit();
  }
  onShareClick() {
    this.btnShareClick.emit();
  }

  onPlayClick() {
    this.btnPlayClick.emit();
  }

  onDetailsClick() {
    this.btnDetailsClick.emit();
  }

  onOrderClick() {
    this.btnOrderClick.emit();
  }
  widget = false
  handleSlideSize() {
    const parentCheck = document.getElementById("yieldList")
    if (parentCheck && parentCheck?.clientWidth < 300) {
      this.widget=true
      return 'flex flex-col px-4 gap-[2rem] items-center'
    } else {
      return 'flex justify-between'
    }

  }

}
