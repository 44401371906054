import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AdminService, UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  registered:boolean | null = null
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly userService: UserService,
    protected readonly adminService: AdminService
  ) {
    super(router, keycloak);
  }
  state:any
  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.state = state
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }
    
    await this.waitForCondition()
    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0 && this.registered) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    if(this.registered)
    return requiredRoles.every((role) => this.roles.includes(role))
  else return false 
  }

  isRegistered() {
    this.userService.get("/investor/authentication_id").subscribe({next:(d)=>{
      this.registered = true
    },error:(e=>{
      console.error("checkerror",e)
      if (e.status == 401) {
        this.keycloak.login({
          redirectUri: window.location.origin + this.state.url
        });
      }
      this.registered = false
      if(e.status == 404)
      this.router.navigate(["start"])
    })})
    return this.registered != null

  }
 
  async waitForCondition(interval = 1000, maxAttempts = 10) {
    let attempts = 0;
  
    while (this.authenticated && !this.isRegistered() && attempts < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, interval));
      attempts++;
    }

    
    if (this.isRegistered()) {
      // Condition met, perform further actions if needed
      console.log('Validation met!');
    } else {
      // Condition not met within the specified attempts
      console.log('Validation not met within the specified attempts.');
    }
  }
}