import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dynamicHtml]',
})
export class DynamicHtmlDirective {
  @Input() dynamicHtml?: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  generateInnerHTMLData(): any {
    setTimeout(() => {
      const div = this.renderer.createElement('div');

      const unumeratedList = `display:inline-block;left:0;top:50%;transform: translateY(-50%);width:8px;height:8px;
      background-color:black;border-radius: 50%;"><li style="position:relative;padding-left:25px;transform: translateY(-35px);`
      const styles:any = {
        'h1': 'font-size:36px; font-weight:bold; margin: 20px 0;',
        'h2': 'font-size:32px; font-weight:bold; margin: 18px 0;',
        'h3': 'font-size:30px; font-weight:500; margin: 16px 0;',
        'h4': 'font-size:26px; font-weight:500; margin: 14px 0;',
        'h5': 'font-size:22px; font-weight:500; margin: 12px 0;',
        'h6': 'font-size:18px; font-weight:500; margin: 10px 0;',
        'p': 'font-size:16px; line-height:1.5; margin: 10px 0;',
        'a': 'color:blue; text-decoration:underline;',
        'ul': 'margin: 20px 0; padding-left: 20px;',
        'ol': 'margin: 10px 0; padding-left: 20px;',
        'li': 'margin: 5px 0; '+unumeratedList,
        'table': 'width: 100%; border-collapse: collapse; margin: 10px 0;',
        'th': 'background: #f4f4f4; border: 1px solid #ddd; padding: 8px; text-align: left;',
        'td': 'border: 1px solid #ddd; padding: 8px;',
        'img': 'max-width: 100%; height: auto;',
        'blockquote': 'margin: 10px 0; padding: 10px; border-left: 5px solid #ccc; background: #f9f9f9;',
        'code': 'font-family: monospace; background: #f4f4f4; padding: 2px 4px; border-radius: 3px;'
        // Add more styles as needed
      };
  
      // Apply the styles to the elements in dynamicHtml
      if (this.dynamicHtml) {
        for (const tag in styles) {
          const style = styles[tag];
          const regex = new RegExp(`<${tag}(.*?)>`, 'g');
          this.dynamicHtml = this.dynamicHtml.replace(regex, `<${tag} style="${style}"$1>`);
        }
      }
      div.innerHTML = `
        ${this.dynamicHtml}
        `;
      this.renderer.appendChild(this.elementRef.nativeElement, div);
    }, 1000);
  }

  ngAfterViewInit() {
    if(this.dynamicHtml)
    this.generateInnerHTMLData()
  }


}
