import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleCheckDirective } from './module-check.directive';
import { WidgetCheckDirective } from './widget-check.directive';
import { IntersectionDirective } from './intersection.directive';
import { DynamicHtmlDirective } from './dynamic-html.directive';



@NgModule({
  declarations: [ModuleCheckDirective, WidgetCheckDirective,IntersectionDirective,DynamicHtmlDirective],
  imports: [
    CommonModule
  ],
  exports: [ModuleCheckDirective,WidgetCheckDirective,IntersectionDirective,DynamicHtmlDirective]
})
export class DirectivesModule { }
