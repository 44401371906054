
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class CommonRestRequests {

    constructor(private client: HttpClient, public baseUrl: String) {
    }

    private solvePath(path: string[]) {
        return path.join("/")
    }

    get(path: string[] | string,params?:any): Observable<any | any[]> {
        return this.client.get<any | any[]>(this.baseUrl + `${typeof (path) == "string" ? "/" + path : "/" + this.solvePath(path)}`+ (params? '?' + new URLSearchParams(params).toString():"")).pipe(
            map((response: any) => {
                return response
            })
        );
    }

    post(path: string[] | string, payload: any): Observable<any | any[]> {

        return this.client.post<any | any[]>(this.baseUrl + `${typeof (path) == "string" ? "/" + path : "/" + this.solvePath(path)}`, payload).pipe(
            map((response: any) => {
                return response
            })
        );
    }
    put(path: string[] | string, payload: any): Observable<any | any[]> {

        return this.client.put<any | any[]>(this.baseUrl + `${typeof (path) == "string" ? "/" + path : "/" + this.solvePath(path)}`, payload).pipe(
            map((response: any) => {
                return response
            })
        );
    }

    delete(path: string[] | string): Observable<any | any[]> {

        return this.client.delete<any | any[]>(this.baseUrl + `${typeof (path) == "string"? "/" + path : "/" + this.solvePath(path)}`).pipe(
            map((response: any) => {
                return response
            })
        );
    }
}
