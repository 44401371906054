<div class="text-center md:text-left p-4 md:p-10 !pb-4 bg-gradient-to-b from-[#564CBD] to-[#CB74EC] lg:h-full md:rounded-2xl mx-auto flex-grow md:flex  ">

    <div class="md:w-7/12">
    <div class="bg-yv rounded-full p-2 px-6 inline-block mt-10">
      Yield<span class=" text-[#81FFAF]">vestor</span>
        Academy</div>
    
    <div class="text-4xl md:!text-[5vw] leading-[10vw] sm:leading-[6vw]  font-bold p-2 uppercase md:mb-10 mt-5">
        Fixed Income 
        Education
    </div>
    <div class="md:!text-[1.5vw] md:mt-10">
      Master Fixed Income investments and build a secure financial future with our empowering educational resources.  </div>

    <!-- <app-button [buttonData]="{
        iconRight: false,
        IconLeft: false,
        disabled: false,
        loading: false,
        classButton: '!bg-[#98F2B9] inline-block mx-auto md:mx-0 my-auto md:mt-5',
        classText: 'text-yv-primary',
        classIcon: '',
        textButton: 'Learn More',
        iconRightUrl: '',
      }" />  -->
      </div>
      <div class="mt-auto md:mx-auto md:my-auto md:w-5/12 md:p-10 flex flex-col items-center justify-center pt-10">
        <img src="assets/website/skills-bg.svg" class="w-full" />
        <img src="assets/website/skills.png" class="w-9/12 relative -translate-y-[120%]" />
      </div>
    </div>