import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent],
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent {
  @Input() allocations: boolean = false
  @Input()
  items: any[] = [
    { title: "Investment Amount", iconUrl: "assets/img/icon/clock.svg", value: "$840", classText: 'text-white' },
    { title: "Interest rate", iconUrl: "assets/img/icon/card.svg", value: "6.15%" },
    { title: "Investment Amount", iconUrl: "assets/img/icon/arrow-base.svg", value: "2.14%", btnClass: '!bg-[#98F2B9]' },
    { title: "Investment Amount", iconUrl: "assets/img/icon/calendar.svg", value: "5", classText: 'text-white' },
  ]
}
