<div *ngIf="aiChat===false" class=" text-white text-left {{ listClass }} md:mt-[30px] md:mb-[40px] flex-grow">
  <div *ngFor="let row of details; let i = index"
    class="lg:flex lg:justify-between p-2 {{ row.rowClass }} {{ i % 2 == 0 ? '' : 'bg-[#ffffff11]' }}  {{enableBar? 'md:flex md:flex-row lg:flex-col xl:flex-row':''}}">
    <div class=" flex-grow font-normal text-[16px] text-[#E1E1E1] {{enableBar? '!font-[700] !text-[#E1E1E1] !text-[0.85rem]':''}} {{
      row.keyClass }}" style="font-family: 'Poppins'">
      {{ row.key }}
    </div>
    <div class="flex items-center">
      <div
        class="pl-6 text-right text-[16px] text-[#F9F9F9] {{enableBar? '!px-6 !text-[#2CC784]':''}} {{ row.valueClass }} {{valueClass}}"
        style="font-family: 'Poppins'">
        {{ row.value }}
      </div>
      <div *ngIf="enableBar" id="progressLine" class="w-[5rem] bg-[#81FFAF33] h-[0.85rem] rounded-xl overflow-hidden">
        <div class="w-[2px] {{getBackgroundColorClass(i)}} h-full rounded-xl" 
        [style.width]="calculateProgressLine(row.value)
        
        "></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="aiChat === true"
  class="overflow-auto text-white text-left w-full {{ listClass }} md:mt-[30px] md:mb-[40px] flex-grow">
  <div *ngFor="let row of details; let i = index"
    class="{{widget?'':'lg:flex'}} p-2 {{row.bond ?'justify-between gap-[5rem]':''}} {{ row.rowClass }} {{ i % 2 == 0 ? '' : 'bg-[#FFFFFF08]' }}">
    <div class="{{row.bond ?'':'hidden'}} flex-grow font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}"
      style="font-family: 'Poppins'">
      {{ row?.bond }}
    </div>
    <div *ngIf="row.bond" class="{{widget?'':'lg:flex'}} flex-col sm:flex-row sm:min-w-[10rem] justify-between">
      <div class="{{row.bond?'':'flex-grow'}}  font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}"
        style="font-family: 'Poppins'">
        {{ row.key }}
      </div>
      <div class="pl-6 text-right text-[16px] text-[#F9F9F9] {{ row.valueClass }} {{valueClass}}"
        style="font-family: 'Poppins'">
        {{ row.value }}
      </div>

    </div>
    <div *ngIf="!row.bond" class=" flex-grow font-normal text-[16px] text-[#E1E1E1] {{ row.keyClass }}"
      style="font-family: 'Poppins'">
      {{ row.key }}
    </div>
    <div *ngIf="!row.bond" class="pl-6 text-right text-[16px] text-[#F9F9F9] {{ row.valueClass }} {{valueClass}}"
      style="font-family: 'Poppins'">
      {{ row.value }}
    </div>
  </div>
</div>