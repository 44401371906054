<app-website-layout [bodyclass]="'bg-white text-black'" title="Boost Your Fixed Income Expertise, From Novice to Pro" subTitle="Master the essentials of Fixed Income investing and navigate the markets with confidence."
    image="assets/website/skills-full.svg">
    <div class="grid  gap-10 p-[10%] max-w-screen-2xl m-auto">
        <div class="text-center">
            <app-tabs 
      [tabsData]="{
        active: '!bg-[#2CC784] shadow-lg shadow-[0px_0px_20px_5px_#81FFAF80] ',
        class:
          'gap-0 bg-[#E1E1E1] min-w-[115%] md:min-w-0 !p-2 mb-9 rounded-full',
        classTab: 'border-0 min-w-40 justify-center text-black'
      }"
            [tabs]="[{name:'All',action:null,},{name:'Fixed Income',action:null},{name:'Cybersecurity',action:null}]" />
        </div>
        <div class="grid md:grid-cols-3">
            <div *ngFor="let slide of  [
    { img: 'assets/website/temp/image.png' },
    { img: 'assets/website/temp/image.png' },
    { img: 'assets/website/temp/image.png' },
    { img: 'assets/website/temp/image.png' },
  ]" class="slide">

                <div class="flex flex-col items-center mx-5">
                    <div class="rounded-2xl w-full flex justify-center ">
                        <img class="w-[100%] md:h-[290px] rounded-2xl max-h-52" [src]="slide.img" />
                    </div>

                    <div class="pb-10">
                        <div class="flex justify-between p-4">
                        <div class="font-normal {{true?'':'text-[#B3B3B3]'}} text-[14px]">01/04/2024</div>
        
                        <div class="text-[#6761A8] font-bold ">23 min. read</div>
                    </div>
                        <div class="font-[500] text-[20px]">
                            Do I Need a Fixed Income Education Before Investing?
                        </div>                        
                    </div>
                </div>


            </div>
        </div>

    </div>

</app-website-layout>