import { AfterViewInit, Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import lottie from 'lottie-web';


@Directive({
  selector: '[appIntersection]',
})
export class IntersectionDirective implements OnInit {
    @Output() elementVisible: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(private elementRef: ElementRef) {}
  
    ngOnInit(): void {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // When the element is intersecting, add a class or apply styles to make it visible
            this.elementRef.nativeElement.style.visibility = 'visible';
            this.elementVisible.emit(true);
          }else {
            this.elementRef.nativeElement.style.visibility = 'hidden';
            this.elementVisible.emit(false);
          }
        });
      });
  
      // Start observing the target element
      observer.observe(this.elementRef.nativeElement);
    }

}

