<div class="mx-auto mt-20 py-10 px-[10%]">
    <div class="mb-10">
        <img src="assets/website/logo.svg" class="w-[15%]" />

    </div>
    <div class="grid md:grid-cols-2 lg:gap-x-10 ">
        <app-website-footer-item 
        [link]="'info@yieldvestor.com'"
        [title]="'General'" [subtitle]="'For general queries, including partnership opportunities'" />
        <div class="grid md:grid-cols-3  row-span-2">
        <app-website-footer-item  class=" col-span-2"
        [link]="'920 Broadway, 2nd Floor, New York, New York 10013'"
        [title]="'Yieldvestor address'" [subtitle]="''" />
         </div>
        <div></div>
        <app-website-footer-item 
        [link]="'sales@yieldvestor.com'"
        [title]="'Sales'" [subtitle]="'Interested in speaking with sales for any of our solutions?'" />
        <div></div> 
       
        <app-website-footer-item 
            [link]="'careers@yieldvestor.com'"
            [title]="'Careers'" [subtitle]="'Interested in joining our team?'" />
      
        
    </div>

    <div class="mt-20 md:left-10">© 2024 Yieldvestor. All Rights Reserved.</div>
</div>