<div id="homeCardBox"
  class="rounded-3xl p-6 bg-yv-primary-70 {{
    cardData.classCard
  }} bg-[length:100%] bg-no-repeat bg-left-bottom flex flex-col"
  style="
    width: 100%;
    height: 100%;
  "
  [ngStyle]="{
    'background-image': bgImageUrl ? 'url('+bgImageUrl+')' : ''
  }"
>
  <div *ngIf="cardData.title" class="flex gap-4 mb-4">
    <img class="float-left" [src]="cardData.headerIconUrl" alt="" />
    <span
      class="text-white font-poppins max-w-[11.7rem] {{ cardData.titleClass }}"
      style="font-weight: 700; font-size: 1.5rem; line-height: 2rem"
      >{{ cardData.title | uppercase }}</span
    >
  </div>

  <div class="flex-grow">
    <ng-content></ng-content>
  </div>

  <div id="footer"
    class="flex justify-between items-center pt-[2rem] {{
      cardData.footerClass
    }}"
  >
    <span
      class="font-poppins text-white max-w-[11.7rem] min-w-[5rem] xl:w-[11.7rem]"
      style="
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 10%;
      "
      >{{ cardData.footerText | uppercase }}
    </span>

    <!-- <img src="assets/img/icon/play.svg" alt="" /> -->
    <div>
      <app-rounded-icon-buttom
        [buttonData]="buttonData"
        (btnClick)="onBtnClick()"
      >
      </app-rounded-icon-buttom>
    </div>
  </div>
</div>
