<div class=" text-center md:text-left bg-white text-black">
    <div class="bg-yv-primary-100 text-white rounded-full p-2 px-6 inline-block mt-10">
      We are Experts</div>
  
  <div class="text-4xl font-bold p-2">
    Learn how to invest and interact with your assistant
  </div>

  <!-- Blog Carroursel Goes Here-->
  <app-blog-carousel />

  </div>