import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/auth/login/login.component';
import { CarouselComponent } from './views/auth/login/carousel/carousel.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { SharedModule } from './shared/shared.module';
import { LandpageComponent } from './views/landpage/landpage.component';
import { FirstStepsComponent } from './views/first-steps/first-steps.component';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/guard';
import { AuthInterceptor } from './services/http-interceptor.service';
import { LandingpageComponent } from './views/landingpage/landingpage.component';
import { FeaturesComponent } from './views/landingpage/features/features.component';
import { SkillsComponent } from './views/landingpage/skills/skills.component';
import { BlogComponent } from './views/landingpage/blog/blog.component';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { AdvisorsComponent } from './views/landingpage/advisors/advisors.component';
import { InvestmentsComponent } from './views/landingpage/investments/investments.component';
import { LearnComponent } from './views/landingpage/learn/learn.component';
import { FAQComponent } from './views/landingpage/faq/faq.component';
import { LayoutComponent } from './views/landingpage/layout/layout.component';
import { BlogPageComponent } from './views/landingpage/blog-page/blog-page.component';
import { BookComponent } from './views/landingpage/book/book.component';
import { InputChatComponent } from './components/input-chat/input-chat.component';
import { FooterComponent as WebsiteFooterComponent } from './views/landingpage/footer/footer.component'; 
import { DirectivesModule } from './directives/directives.module';
import { WebsiteMobileMenuComponent } from './views/landingpage/layout/mobile-menu/mobile-menu.component';
import { AcademyComponent } from './views/landingpage/academy/academy.component';
import { ConvertToUSD } from './services/pipes';
import { RouterModule } from '@angular/router';

function initializeKeycloak(
  keycloak: KeycloakService,
  keycloackAuth: Keycloak
) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloarUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClient,
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      shouldUpdateToken: (request) => {
        if (request.headers.get('token-update') === 'false') {
          return false;
        } else {
          return true;
        }
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LandingpageComponent,
    LandpageComponent,
    LoginComponent,
    CarouselComponent,
    RegisterComponent,
    FirstStepsComponent,
    LayoutComponent,
    WebsiteMobileMenuComponent,
    FAQComponent,
    BlogPageComponent,
    BookComponent,
    AcademyComponent,
    //Rountings

    //Directives

    //Pipes
    ConvertToUSD

  ],
  imports: [
    DirectivesModule,
    KeycloakAngularModule,
    HttpClientModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    LottieComponent,
    RouterModule,
    FeaturesComponent,
    SkillsComponent,
    BlogComponent,
    AdvisorsComponent,
    InvestmentsComponent,
    LearnComponent,
    InputChatComponent,
    WebsiteFooterComponent,
    
  
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
