export const environment = {
  production: false,
  keycloarUrl: 'https://qa-auth.yieldvestor.com',
  keycloakRealm: 'demo-yieldvestor',
  keycloakClient: 'yv-public',
  ROBO_API:'https://qa-robo.yieldvestor.com',
  ROBOADV_API:"https://qa.yieldvestor.com/roboadv/inv/api/v1",
  RAMPMS_API:"https://qa.yieldvestor.com/rampms/roboadv/ra-pf/api/v1",
  CHAT_API:"https://qa.yieldvestor.com/conversational-ai/api/v1",
  ONBOARD_API:"https://qa.yieldvestor.com/onboard/api/v1",
  FIXED_INCOME_API:"https://qa.yieldvestor.com/fixed-income",
  ADMIN_API:"https://qa.yieldvestor.com/adminapi",
  CMS_API:"https://dev-cms.yieldvestor.com",
  //keycloakRealm: 'rody',
  //keycloakClient: 'yv-public',
};
