
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import User from "./UserRepo";
import { CommonRestRequests } from "../CommonRestRequests";
import { environment } from "src/environments/environment";

@Injectable()
export class AdminServiceRepoRest extends CommonRestRequests implements User {
    url: string;
    constructor(private http: HttpClient) {
        super(http, environment.ADMIN_API)
        this.url =environment.ADMIN_API;
    }
    async getAsync(type: string): Promise<any | any[]> {
        return this.http.get<any | any[]>(this.url + `${type}`).pipe(
            map((response: any) => {
                return response
            })
        );
    }
}