<div class="grid lg:grid-cols-4 md:grid-cols-3 gap-10 p-10">

<div class=" bg-white rounded-2xl flex flex-col items-center mt-[50%]" *ngFor="let obj of  ['','','','']">
    <img class="relative -translate-y-[50%] w-1/2" src="assets/website/temp/round.png" />
    <div class="-translate-y-1/4">
        <h1 class="font-bold text-2xl mb-5">Gregor Adams</h1>
        <div class="my-5">
            <span class="text-[#6761A8] text-3xl">122+ </span>
            <span class="">Investiments</span>"
        </div>
        <span class="bg-[#98F2B933] text-[#2CC784] p-2">per 24M</span>
        <div class="flex gap-4 justify-center my-5">
            <app-rounded-icon-buttom  [buttonData]="{
                iconUrl: 'assets/website/icon/in.svg',
                btnClass: '!bg-[#B3B3B3]'
              }"/>
            <app-rounded-icon-buttom [buttonData]="{
                iconUrl: 'assets/website/icon/in.svg',
                btnClass: '!bg-[#B3B3B3]'
              }"/>
            <app-rounded-icon-buttom [buttonData]="{
                iconUrl: 'assets/website/icon/in.svg',
                btnClass: '!bg-[#B3B3B3]'
              }"/>
        </div>
    </div>
</div>
    
</div>
