import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { SelectForm, SelectItems } from 'src/app/models/ComponentInput';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-form',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent,FormsModule,ReactiveFormsModule],
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.scss'],
})
export class SelectFormComponent {
  @Input() control: FormControl = new FormControl()
  @Input() selectData: SelectForm = {
    selectFormClass: '',
    label: 'Label',
    labelClass: '',
    selectClass: '',
    id: '',
    name: '',
    optionClass:"",
    
  };

  @Input() selectedType: string = '';
  @Input() defaultOption: boolean = true;
  @Input() default: any =  {value: null, name: 'Select'};
  @Input() items: SelectItems[] = [ { value: 'A002', name: 'My Retirement Plan'}];
  @Input() showMode:boolean = false
  
  @Output() selectChange = new EventEmitter<any>();

  onSelectChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (selectedValue) {
      this.selectChange.emit(selectedValue);
    }
  }
  
  
  
}
