import { Component } from '@angular/core';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';

@Component({
  selector: 'app-learn',
  standalone: true,
  imports: [VideoCarouselComponent],
  templateUrl: './learn.component.html',
  styleUrl: './learn.component.scss'
})
export class LearnComponent {

}
