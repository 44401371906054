import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './footer/footer.component';
import { YieldvestorComponent } from './yieldvestor/yieldvestor.component';
import { RoundedIconButtomComponent } from '../components/rounded-icon-buttom/rounded-icon-buttom.component';
import { UserLayoutComponent } from './Layouts/user-layout/user-layout.component';
import { VideoCardComponent } from '../components/video-card/video-card.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ButtonComponent } from '../components/button/button.component';
import { ChatBoxComponent } from './welcome/chat-box/chat-box.component';
import { StepperComponent } from './stepper/stepper.component';
import { StepperCounterComponent } from './stepper-counter/stepper-counter.component';
import { BackAndForwardComponent } from '../components/back-and-forward/back-and-forward.component';
import { TableComponent } from './table/table.component';
import { MyBondsTableComponent } from './my-bonds-table/my-bonds-table.component';
import { BondsCompanyComponent } from '../components/bonds-company/bonds-company.component';
import { ComparisonsTableComponent } from './comparisons-table/comparisons-table.component';
import { TwoDTableComponent } from './two-d-table/two-d-table.component';
import { RouterModule } from '@angular/router';
import { MobileMenuComponent } from './Layouts/user-layout/mobile-menu/mobile-menu.component';
import { SideBarComponent } from './Layouts/user-layout/side-bar/side-bar.component';
import { InputFormComponent } from '../components/input-form/input-form.component';
import { SelectFormComponent } from '../components/select-form/select-form.component';
import { FilterComponent } from './filter/filter.component';
import { RangeSliderComponent } from '../components/range-slider/range-slider.component';
import { LevelComponent } from '../components/level/level.component';
import { AmountComponent } from '../components/amount/amount.component';
import { SortViewComponent } from '../components/sort-view/sort-view.component';
import { EducationLayoutComponent } from './Layouts/education-layout/education-layout.component';
import { AnalitcsCardComponent } from './analitcs-card/analitcs-card.component';
import { StepperVerticalComponent } from './stepper-vertical/stepper-vertical.component';
import { SocialButtonComponent } from '../components/social-button/social-button.component';
import { HomeCardComponent } from '../components/home-card/home-card.component';
import { AssetsVisualizationComponent } from '../components/assets-visualization/assets-visualization.component';
import { DetailsComponent } from '../components/details/details.component';
import { TabsComponent } from '../components/tabs/tabs.component';
import { BondsWatchlistAssetsComponent } from '../components/bonds-watchlist-assets/bonds-watchlist-assets.component';
import { UploadPhotoComponent } from '../components/upload-photo/upload-photo.component';
import { WalletCardComponent } from '../components/wallet-card/wallet-card.component';
import { IssuesCalendarComponent } from './issues-calendar/issues-calendar.component';
import { TextCardComponent } from './text-card/text-card.component';
import { InputChatComponent } from '../components/input-chat/input-chat.component';
import { FormsModule } from '@angular/forms';
import { LoadingLogoComponent } from './loading-logo/loading-logo.component';
import { MyCartTableComponent } from './my-cart-table/my-cart-table.component';
import { InfoCardActionsComponent } from './info-card-actions/info-card-actions.component';
import { InfoChatComponent } from './info-chat/info-chat.component';
import { DirectivesModule } from '../directives/directives.module';
import { ValueContainerComponent } from './value-container/value-container.component';
import { IncomeScheduleComponent } from './income-schedule/income-schedule.component';
import { SummaryComponent } from '../components/summary/summary.component';

export const COMMON_IMPORTS = [
  RoundedIconButtomComponent,
  SlickCarouselModule,
  SocialButtonComponent,
  HomeCardComponent,
  AssetsVisualizationComponent,
  DetailsComponent,
  TabsComponent,
  BondsCompanyComponent,
  BondsWatchlistAssetsComponent,
  SelectFormComponent,
  UploadPhotoComponent,
  WalletCardComponent,
  ButtonComponent,
  InputFormComponent,
  SummaryComponent
];
@NgModule({
  declarations: [
    StepperVerticalComponent,
    VideoCarouselComponent,
    FooterComponent,
    YieldvestorComponent,
    UserLayoutComponent,
    WelcomeComponent,
    ChatBoxComponent,
    StepperComponent,
    StepperCounterComponent,
    TableComponent,
    MyBondsTableComponent,
    ComparisonsTableComponent,
    TwoDTableComponent,
    MobileMenuComponent,
    SideBarComponent,
    FilterComponent,
    EducationLayoutComponent,
    AnalitcsCardComponent,
    IssuesCalendarComponent,
    TextCardComponent,
    LoadingLogoComponent,
    MyCartTableComponent,
    InfoCardActionsComponent,
    InfoChatComponent,
    ValueContainerComponent,
    IncomeScheduleComponent,
  ],
  exports: [
    VideoCarouselComponent,
    FooterComponent,
    YieldvestorComponent,
    UserLayoutComponent,
    WelcomeComponent,
    StepperComponent,
    StepperCounterComponent,
    TableComponent,
    MyBondsTableComponent,
    ComparisonsTableComponent,
    TwoDTableComponent,
    MobileMenuComponent,
    FilterComponent,
    EducationLayoutComponent,
    AnalitcsCardComponent,
    StepperVerticalComponent,
    IssuesCalendarComponent,
    TextCardComponent,
    LoadingLogoComponent,
    InfoCardActionsComponent,
    InfoChatComponent,
    ValueContainerComponent,
    IncomeScheduleComponent,
    ...COMMON_IMPORTS,
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    VideoCardComponent,
    BackAndForwardComponent,
    BondsCompanyComponent,
    RouterModule,
    RangeSliderComponent,
    LevelComponent,
    AmountComponent,
    SortViewComponent,
    InputChatComponent,
    FormsModule,
    DirectivesModule,
    ...COMMON_IMPORTS,
  ],
})
export class SharedModule {}
